import './src/scss/main.scss';

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  const { pathname, hash } = location;
  const noScrollToTopRoutes = [`/`];
  if (noScrollToTopRoutes.indexOf(pathname) === -1) {
    window.scrollTo({ top: 0, behavior: 'instant' });
  } else {
    if (hash.includes('work')) {
      console.log('setting scroll top behaviour');
      window.scrollTo({ top: '100vh', behavior: 'smooth' });
      return;
    }
    const [, top] = getSavedScrollPosition(location);
    window.scrollTo({ top, behavior: 'instant' });
  }
};
